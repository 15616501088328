import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import logo from "../../Images/logo-Z.png"

export default function PageContainer({ children }) {
	const store = useSessionStore();
	const navigate = useNavigate();

	return 	<div>
                <Row className="text-white p-2" style={{backgroundColor: "#009845"}}>
                    <Col className="col-5 d-flex align-items-center">
                                <img style={{ width: "50px" }} src={ logo }></img>
                        <h5 className="ps-3">
                            Zing Fibre - Admin Portal
                        </h5>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center pe-4">
                        <Button onClick={ e => {store.Logout(); navigate("/")} } className="btn-logout">Logout</Button>
                    </Col>
                </Row>
				<Row className="h-100 overflow-hidden p-3 bg-light">
					<Col>
						{ children }
					</Col>
				</Row>
			</div>
}