import { HashRouter, Routes, Route } from "react-router-dom";
import RegistrationApproval from "./RegistrationApproval/RegistrationApproval";
import Addresses from "./Addresses/Index/Addresses";

export default function Main() {
    return <HashRouter>
                <Routes>
                    <Route path="RegistrationApproval/:customerId" element={ <RegistrationApproval></RegistrationApproval> }></Route>
                    <Route path="/" element={ <Addresses></Addresses> }></Route>
                </Routes>
            </HashRouter>
}