import { useState, useEffect } from "react";
import { Form, Row, Col, Modal, Stack, Alert } from "react-bootstrap";
import { Field, Formik, Form as FormikForm } from "formik";
import * as yup from "yup";
import { useSessionStore } from "../../../../Stores/SessionStore";
import AlertModal from "../../../Core/AlertModal";

export default function InstallCompleteModal({show, onClose, address}) {
    const store = useSessionStore();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [saving, setSaving] = useState(false);

    const [details, setDetails] = useState({
        addressId: null,
        splitter: "",
        serialNumber: "",
        radiusUsername: "",
        W3W: "",
        InstallDate: ""
    });

    useEffect(() => {
        if (address && address.Id) {
            getRadiusName(address.Id);
        }
    }, [address]);

    const handleSubmit = async (data) => {

        setSaving(true);

        const { splitter, serialNumber, radiusUsername, w3w, installDate } = data;
        
        const result = await store.AddressesService.MarkInstallationAsComplete(address.Id, splitter, serialNumber, radiusUsername, w3w, installDate);
        
        if (result && result.Success) {
            setSuccessMessage("Installation marked as complete");
            setShowAlert(true);
        } else {
            if (result && result.Message && result.Message.includes("DOCTYPE")) {
                setAlertMessage("An error occurred while marking installation as complete");
                setShowAlert(true);
            } else if (result && result.Message) {
                setAlertMessage(result.Message);
                setShowAlert(true);
            }
        }
        setSaving(false);
        return;
    }

    async function getRadiusName(addressId) {

        const result = await store.AddressesService.GetRadiusName(addressId);

        if (result && result.Success) {
            setDetails({...details, addressId: address.Id, radiusUsername: result?.Message});
        } else {
            Alert("Error", result && result.Message ? result.Message : "Error retrieving Radius Name", "error");
        }
    }

    const validationSchema = yup.object().shape({		
        radiusUsername: yup.string().required("Radius Username is required"),
        serialNumber: yup.string().required("CPE Serial Number is required"),
        splitter: yup.string().required("Splitter Number is required"),
        w3w: yup.string().required("W3W is required"),
        installDate: yup.date().required("Install Date is required")
	}).required();

    return (
        <Modal show={ show } dialogClassName="modal-install-complete">
            <Modal.Header className="modal-admin-header">
                <div className="modal-admin-heading">Install Complete</div>
            </Modal.Header>
            <Formik initialValues={details} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true} validateOnMount>
                {({ errors, touched }) => (
                    <FormikForm>                                    
                        <Modal.Body>
                            <Stack gap={3}>
                                <Row>
                                    <Form.Group as={Row}>
                                        <Col className="col-4 d-flex align-items-center">
                                            <Form.Label>Radius Username</Form.Label>
                                        </Col>
                                        <Col className="col-8">
                                            <Field isInvalid={ errors.radiusUsername } label="Radius Username" name="radiusUsername"  as={Form.Control}></Field>
                                                {
                                                    errors.radiusUsername  &&
                                                    <small className="text-danger">{ errors.radiusUsername }</small>
                                                }
                                        </Col>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Row}>
                                        <Col className="col-4 d-flex align-items-center">
                                            <Form.Label>CPE Serial Number</Form.Label>
                                        </Col>
                                        <Col className="col-8">
                                            <Field isInvalid={ errors.serialNumber } label="CPE Serial Number" name="serialNumber"  as={Form.Control}></Field>
                                                {
                                                    errors.serialNumber  &&
                                                    <small className="text-danger">{ errors.serialNumber }</small>
                                                }
                                        </Col>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Row}>
                                        <Col className="col-4 d-flex align-items-center">
                                            <Form.Label>Splitter Number</Form.Label>
                                        </Col>
                                        <Col className="col-8">
                                            <Field isInvalid={ errors.splitter } label="Splitter Number" name="splitter" as={Form.Control}></Field>
                                                {
                                                    errors.splitter  &&
                                                    <small className="text-danger">{ errors.splitter }</small>
                                                }
                                        </Col>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Row}>
                                        <Col className="col-4 d-flex align-items-center">
                                            <Form.Label>W3W</Form.Label>
                                        </Col>
                                        <Col className="col-8">
                                            <Field isInvalid={ errors.w3w } label="W3W" name="w3w" as={Form.Control}></Field>
                                                {
                                                    errors.w3w  &&
                                                    <small className="text-danger">{ errors.w3w }</small>
                                                }
                                        </Col>
                                    </Form.Group>
                                </Row>                                
                                <Row>
                                    <Form.Group as={Row}>
                                        <Col className="col-4 d-flex align-items-center">
                                            <Form.Label>Install Date</Form.Label>
                                        </Col>
                                        <Col className="col-8">
                                        <Field isInvalid={ errors.InstallDate } label="Install Date" name="installDate" as={Form.Control} type="date"></Field>
                                            {
                                                errors.InstallDate  &&
                                                <small className="text-danger">{ errors.InstallDate }</small>
                                            }
                                        </Col>
                                    </Form.Group>
                                </Row>
                            </Stack>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-admin-primary">{saving ? "Saving..." : "Save"}</button>
                            <button type="button" className="btn btn-admin-secondary" onClick={() => onClose(false)}>Cancel</button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
            <AlertModal                    
                show={showAlert}
                onClose={() => {
                    setShowAlert(false);
                    setAlertMessage("");
                    setSuccessMessage("");
                    if (successMessage) onClose(true);}
                }
                message={alertMessage ? alertMessage : successMessage}
                success={successMessage}                            
            />
        </Modal>
    )
}