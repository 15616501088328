import { Card } from "react-bootstrap";

export default function Panel({ children, title }) {
	return 	<Card  className="shadow p-3 mb-3">
			{
				title &&
				<Card.Header>
					<h4 className="m-0 py-1">{title}</h4>
				</Card.Header>
			}
				<Card.Body>
					{children}
				</Card.Body>
			</Card>
}