import { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import SearchField from "../../../Core/SearchField";

export default function Headerbar({ onAddClicked, handleBulkExport, handleBulkImport, handleSearch }) {

	const [search, setSearch] = useState("");

	return 	<Row>
				<Col className="col-12 col-lg-4 mb-3 mb-lg-0">
					<SearchField label="Search" name="search" value={search} onChange={(e) => {setSearch(e.target.value)}} onClick={() => handleSearch(search)} disabled={false} />
				</Col>				
				<Col className="col-12 col-lg-3 col-xl-2 col-xxl-2 offset-lg-2">
					<Button className="btn w-100 btn-sm btn-admin-secondary" onClick={onAddClicked}>Add New Address</Button>
				</Col>
				<Col className="col-12 col-lg-3 col-xl-2 col-xxl-2">
					<Button className="btn w-100 btn-sm btn-admin-secondary" onClick={handleBulkImport}>Bulk Import</Button>
				</Col>
				<Col className="col-12 col-lg-3 col-xl-2 col-xxl-2">
					<Button className="btn w-100 btn-sm btn-admin-secondary" onClick={handleBulkExport}>Bulk Export</Button>
				</Col>
			</Row>
}