import { useState, useEffect } from "react";
import { Form as FormikForm, Formik } from "formik";
import { Button, Card, Col, Row, Container } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import PasswordField from "../../Core/PasswordField";
import AlertModal from "../../Core/AlertModal";


export default function ResetPassword() {
    const store = useSessionStore();
    const navigate = useNavigate();
    const params = useParams();

    const [details, setDetails] = useState({Id: null, Password: "", ConfirmPassword: ""});
    const [resetting, setResetting] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        if (params && params.id) {
            setDetails({...details, Id: params.id});
        }
    }, [params]);

    async function handleFormSubmitClicked(values) {
        setResetting(true);

        var result = await store.UsersService.ResetPassword(values.Id, values.Password, values.ConfirmPassword);

        if (result && result.Success) {
            setSuccessMessage("Password updated successfully");
            setShowAlert(true);
            setResetting(false);
        } else {
            setAlertMessage(result.Message);
            setShowAlert(true);
            setResetting(false);
        }
    }

    function onAlertClose() {
        setShowAlert(false);
        navigate("/");
    }

    const validationSchema = yup.object().shape({
                Password: yup.string().min(6, "Password must be at least 6 characters.").required("Confirm Password is required."),
                ConfirmPassword: yup.string().oneOf([yup.ref("Password"), null], "Passwords must match.").required("Confirm Password is required.")
            }).required();

    return <>
                <Container size="lg">
                    <Row className="d-flex justify-content-center mt-5 pt-5">
                        <Col lg={6}>
                            <Card>
                                <Card.Header>
                                    <h5>Reset Password</h5>
                                </Card.Header>
                                <Formik validationSchema={validationSchema} initialValues={details} onSubmit={handleFormSubmitClicked} enableReinitialize={true}>
                                    {({ errors, touched }) => (
                                        <FormikForm>
                                            <Card.Body className="">
                                                <PasswordField isInvalid={touched.Password && errors.Password} label="Password" name="Password" passwordError=""/>
                                                {
                                                    touched.Password && errors.Password &&
                                                    <small className="text-danger">{errors.Password}</small>
                                                }
                                                <PasswordField isInvalid={touched.ConfirmPassword && errors.ConfirmPassword} label="Confirm Password" name="ConfirmPassword" />
                                                {
                                                    touched.ConfirmPassword && errors.ConfirmPassword &&
                                                    <small className="text-danger">{errors.ConfirmPassword}</small>
                                                }
                                                </Card.Body>
                                                <Card.Footer className="d-flex justify-content-end">
                                                    {                                            
                                                        <>
                                                            <Button className="me-2 btn-admin-primary" type="submit" disabled={resetting}>{resetting ? "Resetting..." : "Reset"}</Button>
                                                            <Button className="btn-admin-primary" disabled={false} onClick={ () => navigate("/")}>Cancel</Button>
                                                        </>
                                                    }
                                                </Card.Footer>
                                            </FormikForm>
                                        )}
                                    </Formik>
                            </Card>	
                        </Col>
                    </Row>
                </Container>
                <AlertModal show={showAlert} onClose={onAlertClose} message={alertMessage ? alertMessage : successMessage} success={successMessage}/>
         </>
}