import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

export default function DropZone({ setFieldValue, isInvalid }) {

    const [selectedFile, setSelectedFile] = useState(null);

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            setFieldValue("ImportFile", files[0]);
            setSelectedFile(files[0].name);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFileSelect = (event) => {
        const files = event.target.files;
        
        if (files && files.length > 0) {
            setFieldValue("ImportFile", files[0]);
            setSelectedFile(files[0].name);
        }
    };

    return (
        <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
                border: isInvalid ? "2px solid #FF0000" : "2px dashed #FF7800",
                borderRadius: "4px",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer"
            }}
        >
            <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileSelect}
                id="fileInput"
            />
            <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                Drag "n" drop, or click to select file
            </label>
            {selectedFile && (
                <div>
                    <div style={{ marginTop: "10px" }} className="flex-column">                    
                            <FontAwesomeIcon icon={faFileCsv} size="2x" color={"#009845"}/>
                    </div>
                    <div style={{ marginTop: "10px" }} className="flex-column">  
                            {selectedFile}
                    </div>
                </div>
            )}
        </div>
    );
}