import { Modal } from "react-bootstrap";

export default function AlertDateModal({show, onClose, message, success}) {

    return (
        <Modal show={ show } dialogClassName="" size="sm">
            <Modal.Header className={success ? "modal-admin-header" : "modal-alert-header"}>
                <div className="modal-admin-heading">{success ? "Success" : "Error"}</div>
            </Modal.Header>            
                <Modal.Body>
                    <div style={{fontSize: "11px"}}>
                        {message}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-admin-secondary" onClick={() => onClose()}>Close</button>
                </Modal.Footer>
        </Modal>
    )
}