import { useEffect, useRef } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Field, useFormikContext } from "formik";

export default function IDNumberInput({ idField, preferenceField }) {
	const preferenceRef = useRef("");
	const { errors, touched, values } = useFormikContext();
	const preference = values[preferenceField];

	useEffect(() => {
		preferenceRef.current = preference;
	}, [preference])
	
	return 	<>
				<Form.Group as={Row} className="">
					<Form.Label column xs={12} sm={6} md={6} className="text-left">ID Type</Form.Label>
					<Col xs={12} sm={3} md={3}><Field as={Form.Check} type="radio" value="ID" label="ID Number" name="IDNumberPreference"></Field></Col>
					<Col xs={12} sm={2} md={3}><Field as={Form.Check} type="radio" value="Passport"  label="Foreign Passport" name="IDNumberPreference"></Field></Col>
				</Form.Group>
				<Form.Group as={Row} className="">
					<Form.Label className="text-left" column sm={6}>{values[preferenceField] === "Passport" ? "Foreign Passport" : "ID Number"}</Form.Label>
					<Col sm={6}>
						<InputGroup>
						<Field isInvalid={errors.IDNumber && touched.IDNumber } as={Form.Control} name="IDNumber" maxLength={values[preferenceField] === "ID" ? 13 : null}></Field>
						</InputGroup>
						<div className="text-left">
							<small className="text-danger text-left">{(touched[idField] || values[idField]) && errors[idField]}</small>
						</div>
					</Col>
				</Form.Group>
			</>
}