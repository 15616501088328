import { useState } from "react";
import { Form, InputGroup, Row } from "react-bootstrap";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

export default function PasswordField({label, name, isInvalid }) {
    const [showPassword, setShowPassword] = useState(false);
    
    return (
		<Form.Group as={Row}>
			<Form.Label>{label}</Form.Label>
			<InputGroup>
				<Field
					name={name}
                    isInvalid={isInvalid}
					type={showPassword ? "text" : "password"}
					className="bg-white"
                    as={Form.Control}
				>                                                
				</Field>
				<InputGroup.Text>
					<FontAwesomeIcon
						className=""
						icon={ showPassword ? faEyeSlash : faEye}
						fixedWidth
						onClick={() => {setShowPassword(!showPassword)}}
					/>
				</InputGroup.Text>
			</InputGroup>
		</Form.Group>
    )
}