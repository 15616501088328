import { Button, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function SearchField({ onChange, onClick }) {
    return (
        <InputGroup className="mb-3">
            <Form.Control
                placeholder="Type here to search"
                onChange={ e => onChange(e)}
                className="search-box"
                onKeyPress={ e => { if (e.key === "Enter") onClick() }
            }
            />
            <Button onClick={onClick} className="btn-admin-secondary">
                <FontAwesomeIcon style={{ cursor: "pointer" }} size={"sm"} className="" icon={ faSearch } ></FontAwesomeIcon>
            </Button>
      </InputGroup>
    )
}