import { useRef, useState } from "react";

export default function FileUpload(props) {
	const fileInputRef = useRef(null);
	const [fileName, setFileName] = useState(props.filename || props.description);
	const [file, setFile] = useState(props.file);

	async function handleFileChange(target) {
		if(target.files.length > 0) {
			var fileReader = new FileReader()
			fileReader.onloadend = () => {
				var result = btoa(fileReader.result);

				setFile(result);
				setFileName(target.files[0].name);

				props.onFileChanged(result, target.files[0].name);
			}

			fileReader.readAsBinaryString(target.files[0]);
		} else {
			setFileName("...");
			setFile(null);
			props.onFileChanged(null, "");
		}
	}

	return 	<div>
				<input accept="image/* , application/pdf" ref={fileInputRef} hidden type="file" id={`ImportFile_${props.name}`} onChange={ e => handleFileChange(e.target) }></input>
				<label htmlFor={`ImportFile_${props.name}`} className="p-2 d-flex justify-content-center align-items-center text-primary h-100" style={{ backgroundColor: "#0166c210", border: "2px dashed #0166c2", borderRadius: "5px", cursor: "pointer" }}>
					<small className="mb-0">{ file === null ? props.description : fileName }</small>
				</label>
			</div>
}