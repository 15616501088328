import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortDirections } from "../../Utilities/Enums";  

export default function SortTableHeader({ onClick, sortBy, sortedBy, sortDirection, children }) {
    return (
        <th
            className="table-admin-sort-header"
            onClick={onClick}
        >
            <span className="d-flex flex-row justify-content-between align-content-start">
                { children }
                <div className="flex-column d-flex justify-content-center ms-1">
                    <FontAwesomeIcon className="align-self-end" color={sortDirection === SortDirections.Ascending && sortedBy === sortBy ? "##212529" : "#DEE2E6"} size={"xs"} icon={ faChevronUp } />
                    <FontAwesomeIcon className="align-self-end" color={sortDirection === SortDirections.Descending  && sortedBy === sortBy ? "##212529" : "#DEE2E6"} size={"xs"} icon={ faChevronDown } />
                </div>
            </span>
        </th>
    );
}