import { observer } from "mobx-react-lite";
import { useSessionStore } from "../Stores/SessionStore";
import Main from "./Pages/Main";
import Auth from "./Pages/Authentication/Auth";

export default observer(function App() {
	const store = useSessionStore();

	if (store.UserIsLoggedIn) {
		return <Main />
	} else {
		return <Auth />
	}
})