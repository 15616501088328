import { useState } from "react";
import { Form, Row, Col, Modal } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import { saveAs } from "file-saver";
import { useSessionStore } from "../../../../Stores/SessionStore";
import DropZone from "../../../Core/DropZone";
import AlertModal from "../../../Core/AlertModal";
import * as Yup from "yup";

export default function BulkImportModal({show, onClose}) {
    const store = useSessionStore(); 

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [saving, setSaving] = useState(false);
    
    async function handleSubmit(data) {

        setSaving(true);

        const reader = new FileReader();
        let lines = "";

        reader.onload = async function(e) {
            lines = e.target.result;
            
            let result = await store.AddressesService.ImportAddresses(lines);

            if (result && result.Success) {
                setSuccessMessage("Addresses imported successfully");
                setShowAlert(true);
            } else {
                setAlertMessage(result && result.Message ? getErrorMessage(result.Message) : "An error occurred while importing addresses");
                setShowAlert(true);
            }

            setSaving(false);
        }

        reader.readAsText(data.ImportFile);
    }

    function getErrorMessage(message) {
        return message.split(". ")[1];
    }

    async function downloadSampleCSV() {

		const csv = "ERF,StreetAddress,MDUName,MDUUnitNumber,MDUBlock,Township,PropertyType,POP";
        const blob = new Blob([csv], { type: "text/csv" });

        saveAs(blob, "SampleAddresses.csv");
	}

    const validationSchema = Yup.object().shape({
        ImportFile: Yup.mixed()
            .required("A file is required")
            .test(
                "fileFormat",
                "Only CSV files are allowed",
                value => value && (value.type === "application/vnd.ms-excel" || value.type === "text/csv")
            )
    });

    return (
        <Modal show={ show } dialogClassName="modal-install-complete">
            <Modal.Header className="modal-admin-header">
                <div className="modal-admin-heading">Bulk Import</div>
            </Modal.Header>
            
                {true &&
                    <>
                        <Formik initialValues={{ImportFile: null}} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
                            {({ setFieldValue , errors, touched }) => (
                                <FormikForm>                                    
                                    <Modal.Body>
                                        <Form.Group as={Row}>
                                            <Col>                                                
                                                <DropZone setFieldValue={setFieldValue} isInvalid={errors.ImportFile ? true : false}/>
                                            </Col>
                                            {
                                                errors.ImportFile &&
                                                <small className="text-danger">{ errors.ImportFile }</small>
                                            }
                                        </Form.Group>
                                        <Row className="mt-3 justify-content-center">
                                            <Col className="col-3 d-flex justify-content-center">
                                                <button type="submit" className="btn btn-admin-primary" onClick={downloadSampleCSV}>Download sample CSV</button>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn btn-admin-primary">{saving ? "Saving..." : "Save"}</button>
                                        <button type="button" className="btn btn-admin-secondary" onClick={() => onClose(false)}>Cancel</button>
                                    </Modal.Footer>
                                </FormikForm>
                            )}
                        </Formik>
                    </>
                }
        <AlertModal            
            show={showAlert}
            onClose={() => {
                setShowAlert(false);
                setAlertMessage("");
                setSuccessMessage("");
                onClose(true);
            }}
            message={alertMessage ? alertMessage : successMessage}
            success={successMessage}                
        />
        </Modal>
    )
}