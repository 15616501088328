import APIBase from "./APIBase";

/**
 * @typedef CustomerRadiusDetails
 * @property {string} ServiceName
 * @property {number} ServiceId
 * @property {string} IPAddress
 * @property {string} AccountType
 * @property {string} ExpiryDate
 * @property {number} CurrentUsage
 * @property {number} Price
 */

/**
 * @typedef CustomerDetails
 * @property {string} Name
 * @property {string} Username
 * @property {string} Language
 * @property {CustomerRadiusDetails} RadiusDetails
 */

/**
 * @typedef RadiusAddress
 * @property {string} Username
 * @property {string} Address
 * @property {string} City
 * @property {string} Zip
 * @property {string} Country
 * @property {string} State
 */

/**
 * @typedef Customer
 * @property {string} Id
 * @property {string} FirstName
 * @property {string} Surname
 * @property {string} Email
 * @property {string} CellphoneNumber
 * @property {string} LanguagePreference
 * @property {string} EmailPreference
 * @property {string} IDNumber
 * @property {string} RadiusUsername
 * @property {string} RegistrationApproved
 */

/**
 * @typedef Document
 * @property {string} Id
 * @property {string} FileName
 * @property {string} Content
 */

/**
 * @typedef CustomerRegistration
 * @property {Customer} Customer
 * @property {Document} IDDocument
 * @property {Document} ProofOfAddress
 */

/**
 * @typedef CustomersService
 * @type {CustomersService}
 */
export class CustomersService extends APIBase {
	/**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Customers", sessionStore);
	}

	/**
     * @returns { import("./APIBase").RequestResult<CustomerRegistration> }
     */
     async GetCustomerRegistrationDetails(customerId) {
        return this.Get(`GetCustomerRegistrationDetails?customerId=${customerId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
	async ApproveCustomerRegistration(customerId) {
		return this.Post(`ApproveCustomerRegistration?customerId=${customerId}`);
	}

		/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
		async DeclineCustomerRegistration(customerId) {
			return this.Post(`DeclineCustomerRegistration?customerId=${customerId}`);
		}


	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
	 async UpdateCustomerRegistrationDetails(customerRegistration) {
		return this.Post(`UpdateCustomerRegistrationDetails`, customerRegistration);
	}
}